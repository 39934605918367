import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { addSetor, updSetor } from "../../services/SrvSetor";
import toast, { Toaster } from "react-hot-toast";

export default function SetoresForm({ token, setor, open, handleClose }) {

    const [formValues, setFormValues] = useState({
        nome: setor?.nome || "",
        descricao: setor?.descricao || ""
    })

    const handleChange = (event) => {
        // console.log('event.target: ', event.target)
        const { name, value } = event.target
        setFormValues({
            ...formValues,
            [name]: value
        })
    }

    useEffect(() => {
        setFormValues({ nome: setor?.nome || "", descricao: setor?.descricao || "" })
    }, [setor])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    component: 'form',
                    onSubmit: async (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        console.log(formJson);

                        if (!setor) {
                            await addSetor(token, formJson).then(
                                (resp) => {
                                    toast.success("Setor cadastrado com sucesso!")
                                },
                                (erro) => {
                                    toast.error(`Ocorreu um erro ao tentar cadastrar o setor: ${erro}`)
                                }
                            )
                        } else {
                            const dados = {
                                ...formJson,
                                "id": setor.id
                            }
                            console.log('dados no update: ', dados)
                            await updSetor(token, dados).then(
                                (resp) => {
                                    toast.success("Setor atualizado com sucesso!")
                                },
                                (erro) => {
                                    toast.error(`Ocorreu um erro ao tentar atualizar o setor: ${erro}`)
                                }
                            )
                        }
                        handleClose();
                    },
                }}
            >
                <DialogTitle>{setor ? "Atualizar Setor" : "Novo Setor"}</DialogTitle>
                <DialogContent>

                    <TextField
                        label="Nome"
                        id="nome"
                        name="nome"
                        variant="standard"
                        fullWidth
                        autoFocus
                        required
                        value={formValues.nome}
                        onChange={handleChange}
                        sx={{ mt: 1 }}
                    />
                    <TextField
                        label="Descrição"
                        id="descricao"
                        name="descricao"
                        variant="standard"
                        fullWidth
                        value={formValues.descricao}
                        onChange={handleChange}
                        sx={{ mt: 2 }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button type="submit">Salvar</Button>
                </DialogActions>
            </Dialog>
            <Toaster toastOptions={{ duration: 10000 }} />
        </>
    )
}
