import axios from "axios";


const baseAPI = process.env.REACT_APP_BASE_API_URL
const setorAPI = axios.create({ baseURL: `${baseAPI}api/funil/setores` })

async function getSetores(token) {
    const authToken = `Token ${token}`
    const resp = await setorAPI.get('/', { headers: { Authorization: authToken }})
    return resp.data
}

async function getSetor(token, pk) {
    const authToken = `Token ${token}`
    const resp = await setorAPI.get(`/${pk}/`, { headers: { Authorization: authToken }})
    return resp.data
}

async function addSetor(token, dados) {
    const authToken = `Token ${token}`
    const resp = await setorAPI.post('/', dados, { headers: { Authorization: authToken, "Content-Type": "application/json" }})
    return resp.data
}

async function updSetor(token, dados) {
    const authToken = `Token ${token}`
    const resp = await setorAPI.put(`/${dados.id}/`, dados, { headers: { Authorization: authToken, "Content-Type": "application/json" }})
    return resp.data
}

async function delSetor(token, pk) {
    const authToken = `Token ${token}`
    const resp = await setorAPI.delete(`/${pk}/`, { headers: { Authorization: authToken }})
    return resp.data
}

export { getSetores, getSetor, addSetor, updSetor, delSetor }
