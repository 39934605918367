import { useEffect, useState } from "react";
import { getListas } from "../../services/SrvTemplateFunil";
import toast, { Toaster } from "react-hot-toast";
import { Box, Fab, List } from "@mui/material";
import TemplateListaFunil from "./TemplateListaFunil";
import TemplateListaFunilForm from "./TemplateListaFunilForm";
import AddIcon from "@mui/icons-material/Add";

export default function TemplateListaFunilList({ token }) {
    const [etapas, setEtapas] = useState([])
    const [showForm, setShowForm] = useState(false)

    async function fetchEtapas() {
        await getListas(token).then(
            (resp) => {
                console.log(resp)
                setEtapas(resp)
            },
            (erro) => {
                toast(`Ocorreu um erro ao recuperar etapas: ${erro}`)
            }
        )
    }

    useEffect(() => {
        fetchEtapas()
    }, [])

    const handleAddClick = () => {
        setShowForm(true)
    }

    const handleClose = () => {
        setShowForm(false)
    }

    return (
        <Box component={List} sx={{ display: "flex", flexDirection: "row", flexGrow: 1, flexWrap: "wrap", width: "100wv", gap: 2, padding: 1 }}>
            {
                etapas.sort((a, b) => a.ordem - b.ordem).map((etapa) => (
                    <TemplateListaFunil key={etapa.id} token={token} etapa={etapa} fetchEtapas={fetchEtapas} />
                ))
            }

            <Fab color="primary" aria-label="add" size="medium" sx={{ mt: 2 }} onClick={handleAddClick}>
                <AddIcon />
            </Fab>
            <TemplateListaFunilForm token={token} show={showForm} handleClose={handleClose} fetchEtapas={fetchEtapas}/>
            <Toaster />
        </Box>
    )
}