import { useEffect, useState } from "react";
import { delAssinante, getAssinantes, newAssinante, updAssinante } from "../../services/ServAssinante";
import { IconButton, Paper, Box, styled, TextField, DialogActions, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Container, MenuItem, Snackbar, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from "formik";
import * as yup from 'yup'
import ufs from "../../helpers/ufs";
import { getSetores } from "../../services/SrvSetor";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row': {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

const AssinantesList = ({ nome, token, profile, isAuthenticated }) => {

    // console.log('token em AssinantesList: ', token)

    const [assinantes, setAssinantes] = useState([])
    const [open, setOpen] = useState(false)
    const [assinanteAtual, setAssinanteAtual] = useState(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [setores, setSetores] = useState([])

    const validationSchema = yup.object({
        nome: yup.string('Informe o nome').required('Nome é obrigatório').max(150, 'Até 150 caracteres'),
        sobrenome: yup.string('Informe o sobrenome').required('Sobrenome é obrigatório').max(150, 'Até 150 caracteres'),
        email_contato: yup.string('Informe o email').email('Informe um email válido').max(150, 'Até 150 caracteres').required('Email é obrigatório'),
        endereco: yup.string('Informe o endereço').required('Endereço é obrigatório').max(200, 'Até 200 caracteres'),
        cidade: yup.string('Informe a cidade').required('Cidade é obrigatório').max(100, 'Até 100 caracteres'),
        estado: yup.string('Informe o estado').required('Estado é obrigatório').max(2, 'Até 2 caracteres'),
        cep: yup.string('Informe o CEP').required('CEP é obrigatório').max(10, 'Até 10 caracteres'),
        tel_fixo: yup.string('Informe o telefone').max(11, 'Até 11 caracteres').required('Telefone é obrigatório'),
        tel_celular: yup.string('Informe o celular').max(11, 'Até 11 caracteres').required('Celular é obrigatório'),
        setor: yup.string('Informe o setor de atuação').required('Setor é obrigatório').max(100, 'Até 100 caracteres'),
        codigo_assinante: yup.string().test('is-read-only', 'Este campo é somente leitura', () => true),
        // assinante: yup.number().required()
    })

    var initialValues = { nome: '', sobrenome: '', email_contato: '', endereco: '', cidade: '', estado: '', cep: '', tel_fixo: '', tel_celular: '', setor: '', codigo_assinante: '' }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            if (assinanteAtual) {
                // console.log('atualizar assinante: ', values);
                // console.log('assinante atual: ', assinanteAtual)
                values.id = assinanteAtual.id
                atualizaAssinante();
            } else {
                values.assinante = profile.assinante
                novoAssinante()
            }

            async function atualizaAssinante() {
                await updAssinante(values, token).then(
                    (response) => {
                        setSnackbarOpen(true);
                        setOpen(false);
                        fetchAssinantes();
                    },
                    (error) => {
                        // console.log('Erro ao atualizar o assinante: ', error)
                        alert(`Erro ao atualizar o assinante: ${error}`)
                    }
                );
            }

            async function novoAssinante() {
                return await newAssinante(values, token).then(
                    (response) => {
                        setSnackbarOpen(true);
                        setOpen(false);
                        fetchAssinantes();
                    },
                    (error) => {
                        // console.log('Erro ao incluir o assinante: ', error)
                        alert(`Erro ao incluir o assinante: ${error}`)
                    }
                );
            }

        }
    })

    async function fetchAssinantes() {
        // console.log('token em fetchAssinantes: ', token)
        const assinantesAPI = await getAssinantes(token)
            .then((response) => {
                // console.log('assinantes recuperados: ', response)
                setAssinantes(response)
            },
                (error) => {
                    // console.log('Erro ao recuperar assinantes: ', error)
                    alert(`Erro ao recuperar assinantes: ${error}`)
                }
            )
    }

    async function fetchSetores() {
        await getSetores(token).then(
            (resp) => {
                setSetores(resp)
            },
            (erro) => {
                alert(`Erro ao recuperar os setores: ${erro}`)
            }
        )
    }

    useEffect(() => {
        fetchAssinantes()
        fetchSetores()
        // eslint-disable-next-line
    }, [token])

    const handleEditContato = (assinante) => {
        setAssinanteAtual(assinante)
        formik.setFieldValue('nome', assinante.nome)
        formik.setFieldValue('sobrenome', assinante.sobrenome)
        formik.setFieldValue('email_contato', assinante.email_contato)
        formik.setFieldValue('endereco', assinante.endereco)
        formik.setFieldValue('cidade', assinante.cidade)
        formik.setFieldValue('estado', assinante.estado)
        formik.setFieldValue('cep', assinante.cep)
        formik.setFieldValue('tel_fixo', assinante.tel_fixo)
        formik.setFieldValue('tel_celular', assinante.tel_celular)
        formik.setFieldValue('setor', assinante.setor)
        formik.setFieldValue('codigo_assinante', assinante.codigo_assinante)
        setOpen(true)
    }

    const handleDeleteContato = async (assinante) => {
        await delAssinante(assinante.id, token).then(
            (response) => {
                setSnackbarOpen(true);
                setOpen(false);
                fetchAssinantes();
            },
            (error) => {
                // console.log('Erro ao excluir o assinante: ', error)
                alert(`Erro ao excluir o assinante: ${error}`)
            }
        )
        // console.log('delete assinante : ', assinante)
    }

    const handleOpen = () => {
        formik.resetForm()
        setAssinanteAtual(null)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };

    const columns = [
        { field: 'nome', headerName: 'Nome', width: 150, editable: true, },
        { field: 'sobrenome', headerName: 'Sobrenome', width: 150, editable: true, },
        { field: 'email_contato', headerName: 'Email', width: 210, editable: true, },
        { field: 'tel_fixo', headerName: 'Telefone', width: 120, editable: true, },
        { field: 'tel_celular', headerName: 'Celular', width: 120, editable: true, },
        { field: 'contatos', headerName: 'Contatos', width: 75, editable: false, valueGetter: (value) => {if (value) {return value.length} else {return 0}}},
        { field: 'listas', headerName: 'Listas', width: 75, editable: false, valueGetter: (value) => {if (value) {return value.length} else {return 0}}},
        {
            field: 'acoes', headerName: 'Ações', flex: 1, minWidth: 150, renderCell: (params) => (
                <>
                    <IconButton aria-label="Editar" size="small" onClick={() => handleEditContato(params.row)}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton aria-label="Excluir" size="small" onClick={() => handleDeleteContato(params.row)}>
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                </>
            )
        }
    ]

    return (
        <Container>
            <Box component={Paper} sx={{ width: '100%' }} >
                <LocalizationProvider locale={ptBR}>
                    <StyledDataGrid
                        rows={assinantes}
                        columns={columns}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15, 20]}
                        disableRowSelectionOnClick
                        autoHeight
                    />                
                </LocalizationProvider>
            </Box>
            <Button variant="contained" onClick={handleOpen} sx={{ marginTop: 2 }}>Novo</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: formik.handleSubmit,
                }}
            >
                <DialogTitle>Assinante</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Entre com as informações do Assinante.
                    </DialogContentText>
                    <TextField
                        fullWidth
                        id='nome'
                        name='nome'
                        label="Nome"
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.nome && Boolean(formik.errors.nome)}
                        helperText={formik.touched.nome && formik.errors.nome}
                        margin="normal" size='small'
                    />
                    <TextField
                        fullWidth
                        id='sobrenome'
                        name='sobrenome'
                        label="Sobrenome"
                        value={formik.values.sobrenome}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.sobrenome && Boolean(formik.errors.sobrenome)}
                        helperText={formik.touched.sobrenome && formik.errors.sobrenome}
                        margin="normal" size='small'
                    />
                    <TextField
                        fullWidth
                        id='email_contato'
                        name='email_contato'
                        label="Email"
                        type="email"
                        value={formik.values.email_contato}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email_contato && Boolean(formik.errors.email_contato)}
                        helperText={formik.touched.email_contato && formik.errors.email_contato}
                        margin='normal' size='small'
                    />
                    <TextField
                        fullWidth
                        id='endereco'
                        name='endereco'
                        label="Endereço"
                        value={formik.values.endereco}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.endereco && Boolean(formik.errors.endereco)}
                        helperText={formik.touched.endereco && formik.errors.endereco}
                        margin='normal' size='small'
                    />
                    <TextField
                        fullWidth
                        id='cidade'
                        name='cidade'
                        label="Cidade"
                        value={formik.values.cidade}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.cidade && Boolean(formik.errors.cidade)}
                        helperText={formik.touched.cidade && formik.errors.cidade}
                        margin='normal' size='small'
                    />
                    <TextField
                        fullWidth
                        id='estado'
                        name='estado'
                        label='UF'
                        value={formik.values.estado}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.estado && Boolean(formik.errors.estado)}
                        helperText={formik.touched.estado && formik.errors.estado}
                        margin='normal' size='small'
                        select
                    >
                        {ufs.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        id='cep'
                        name='cep'
                        label='CEP'
                        value={formik.values.cep}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.cep && Boolean(formik.errors.cep)}
                        helperText={formik.touched.cep && formik.errors.cep}
                        margin='normal' size='small'
                    />
                    <TextField
                        fullWidth
                        id='tel_fixo'
                        name='tel_fixo'
                        label='Telefone'
                        value={formik.values.tel_fixo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.tel_fixo && Boolean(formik.errors.tel_fixo)}
                        helperText={formik.touched.tel_fixo && formik.errors.tel_fixo}
                        margin='normal' size='small'
                    />
                    <TextField
                        fullWidth
                        id='tel_celular'
                        name='tel_celular'
                        label='Celular'
                        value={formik.values.tel_celular}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.tel_celular && Boolean(formik.errors.tel_celular)}
                        helperText={formik.touched.tel_celular && formik.errors.tel_celular}
                        margin='normal' size='small'
                    />
                    <TextField
                        fullWidth
                        id='setor'
                        name="setor"
                        label="Setor"
                        value={formik.values.setor}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.setor && Boolean(formik.errors.setor)}
                        helperText={formik.touched.setor && formik.errors.setor}
                        margin="normal"  size="small"
                        select
                    >
                        {
                            (setores.length > 0) ? (
                                setores.map(s => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.nome}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem key={1} value={-1}>
                                    Não há setores cadastrados!
                                </MenuItem>
                            )
                        }
                    </TextField>
                    <TextField
                        fullWidth
                        id='codigo_assinante'
                        name='codigo_assinante'
                        label='Código de Assinante'
                        value={formik.values.codigo_assinante}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.codigo_assinante && Boolean(formik.errors.codigo_assinante)}
                        helperText={formik.touched.codigo_assinante && formik.errors.codigo_assinante}
                        margin='normal' size='small'
                        InputProps={{ readOnly: true }} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button type='submit'>Salvar</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                Dados enviados com sucesso!
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default AssinantesList
