import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import TemplateListaSelect from "./TemplateListaSelect";
import { addTemplates, getListas, updTemplates } from "../../services/SrvTemplateFunil";
import { getSetores } from "../../services/SrvSetor";
import toast, { Toaster } from "react-hot-toast";

export default function TemplateFunilForm({ template, visible, handleClose, token, fetchTemplates }) {

    const [formValues, setFormValues] = useState({
        nome: template ? template.nome : '',
        setor: template ? template.setor.id : '',
        // listas: template ? template.listas.map(lista => { return { "id": lista.id, "nome": lista.nome } }) : []
        listas: template ? template.listas : []
    })

    const [options, setOptions] = useState([])
    const [setores, setSetores] = useState([])

    // Lista completa de opções
    async function fetchListas() {
        const resposta = await getListas(token).then(
            (resp) => {
                // console.log(`no fetchlistas: `, resp)
                const listas = resp.filter(fLista => fLista.setores.includes(formValues.setor)).map(lista => {
                    return { "id": lista.id, "nome": lista.nome }
                })
                setOptions(listas)
            },
            (erro) => {
                toast.error(`Erro ao recuperar listas: ${erro}`)
            }
        )
        return resposta
    }

    async function fetchSetores() {
        await getSetores(token).then(
            (resp) => {
                setSetores(resp)
            },
            (erro) => {
                toast.error(`Ocorreu um erro ao recuperar os setores: ${erro}`)
            }
        )
    }

    useEffect(() => {
        fetchListas()
        fetchSetores()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchListas()
        // eslint-disable-next-line
    }, [formValues.setor])

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    return (
        <>

            <Dialog
                open={visible}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: async (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        console.log('formJson no submit - antes: ', formJson);
                        var listas = formJson['listas']
                        listas = listas.split(',')
                        formJson['listas'] = listas
                        formJson['setor_id'] = formJson['setor']
                        console.log('formJson no submit - depois: ', formJson);
                        if (!template) {
                            await addTemplates(token, formJson).then(
                                (resp) => {
                                    toast.success('Template salvo com sucesso!')
                                    fetchTemplates()
                                    setFormValues({
                                        nome: "",
                                        listas: []
                                    })
                                    handleClose()
                                },
                                (erro) => {
                                    toast.error('Ocorreu um erro ao tentar salvar o template: ', erro)
                                }
                            )
                        } else {
                            await updTemplates(token, template.id, formJson).then(
                                (resp) => {
                                    toast.success('Template atualizado com sucesso!')
                                    fetchTemplates()
                                    handleClose()
                                },
                                (erro) => {
                                    toast.error(`Ocorreu um erro ao tentar salvar o template: ${erro}`)
                                }
                            )
                        }
                    },
                }}
            >
                <DialogTitle>{template ? template.nome : "Novo Funil"}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Nome"
                        id="nome"
                        name="nome"
                        variant="outlined"
                        value={formValues.nome}
                        onChange={handleFormChange}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Para qual setor?"
                        id="setor"
                        name="setor"
                        fullWidth
                        required
                        value={formValues.setor}
                        onChange={handleFormChange}
                        select
                        sx={{ mb: 2 }}
                    >
                        {
                            setores.map((opSetor) => (
                                <MenuItem key={opSetor.id} value={opSetor.id}>
                                    {opSetor.nome}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                    {
                        formValues.setor !== '' && (
                            <TextField
                                label="Selecione listas"
                                id="listas"
                                name="listas"
                                select
                                fullWidth
                                required
                                value={formValues.listas}
                                onChange={handleFormChange}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((id) => {
                                                const option = options.find((opt) => opt.id === id);
                                                return option ? <Chip key={id} label={option.nome} /> : null;
                                            })}
                                        </Box>
                                    ),
                                }}
                                sx={{ mb: 2 }}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.nome}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )
                    }


                    {/* <TemplateListaSelect id="listas" name="listas" token={token} onChange={handleFormChange} sx={{ m: 2 }}/> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button type="submit">Salvar</Button>
                </DialogActions>
            </Dialog>
            <Toaster />
        </>
    )
}