import { useEffect, useState } from "react"
import HomeBar from "../components/HomeBar/HomeBar"
import { Accordion, AccordionDetails, AccordionSummary, Container, Fab, Typography } from "@mui/material"
import TemplateFunil from "../components/TemplateFunil/TemplateFunil"
import { useLocation } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add'
import TemplateFunilForm from "../components/TemplateFunil/TemplateFunilForm"
import { getTemplates } from "../services/SrvTemplateFunil"
import { getSetores } from "../services/SrvSetor"
import toast, { Toaster } from "react-hot-toast"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export default function Funis() {

    const [setores, setSetores] = useState([])
    const [templates, setTemplates] = useState([])
    const [formVisible, setFormVisible] = useState(false)

    var nome = null
    var token = null
    var profile = null
    var isAuthenticated = false

    const location = useLocation()
    const { state } = location

    if (state) {
        nome = state.nome
        token = state.token
        profile = state.profile
        isAuthenticated = state.isAuthenticated
    }

    const fetchSetores = async () => {
        await getSetores(token).then(
            (resp) => {
                setSetores(resp)
            },
            (erro) => {
                toast.error(`Ocorreu um erro ao recuperar os setores: ${erro}`)
            }
        )
    }

    const fetchTemplates = async () => {
        await getTemplates(token).then(
            (resp) => {
                setTemplates(resp)
            },
            (erro) => {
                toast.error(`Erro ao recuperar templates: ${erro}`)
            }
        )
    }

    useEffect(() => {
        fetchSetores()
        fetchTemplates()
        // eslint-disable-next-line
    }, [])

    const handleAddClick = () => {
        setFormVisible(true)
    }

    const handleClose = () => {
        setFormVisible(false)
    }

    return (
        <>
            <HomeBar nome={nome} token={token} profile={profile} isAuthenticated={isAuthenticated} />
            <Container sx={{ marginTop: 5, paddingTop: '69px' }}>
                <Typography variant="h4" gutterBottom>
                    Funis
                </Typography>
                {
                    setores.map((setor) => {
                        return (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`setor${setor.id}-content`}
                                    id={`setor${setor.id}-header`}
                                >
                                    <Typography variant="h6">{`Funis para ${setor.nome}`}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        templates.filter(template => template.setor.id === setor.id).map((template) => (
                                            <TemplateFunil template={template} key={template.id} token={token} fetchTemplates={fetchTemplates} />
                                        ))
                                    }
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
                
                <Fab color="primary" aria-label="add" size="medium" sx={{ mt: 2 }} onClick={handleAddClick}>
                    <AddIcon />
                </Fab>
                <TemplateFunilForm visible={formVisible} handleClose={handleClose} token={token} fetchTemplates={fetchTemplates} />
                <Toaster toastOptions={{ duration: 10000 }} />
            </Container>
        </>
    )
}
