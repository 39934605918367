import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './routes/Login';
import Home from './routes/Home';
import Register from './routes/Register';
import Contatos from './routes/Contatos';
import Assinantes from './routes/Assinantes';
import Biblioteca from './routes/Biblioteca';
import { Funil } from './routes/Funil';
import AlterarSenha from './routes/AlterarSenha';
import { NotificationProvider } from './components/Notifications/NotificationProvider';
import EsqueciSenha from './routes/EsqueciSenha';
import RedefinirSenha from './routes/RedefinirSenha';
import Funis from './routes/Funis';
import Setores from './routes/Setores';
import Etapas from './routes/Etapas';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


// const theme = createTheme({
//   palette: {
//     background: {
//       default: '#ffffff',
//     },
//     text: {
//       primary: '#5c00cd',
//     },
//     primary: {
//       main: '#5c00cd',
//     }
//   },
//   typography: {
//     allVariants: {
//       color: '#5c00cd',
//     },
//   },
//   components: {
//     MuiCardActions: {
//       styleOverrides: {
//         root: {
//           '& .MuiButton-root': {
//             variant: 'text', // Define "text" como padrão para botões dentro de CardActions
//           },
//         },
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           backgroundColor: '#5c00cd',
//           color: '#ffffff',
//           '&:hover': {
//             backgroundColor: '#d1c4e9', // Cor de fundo ao passar o mouse
//           },
//         },
//       },
//     },
//     MuiInputLabel: {
//       styleOverrides: {
//         root: {
//           color: '#5c00cd',
//         },
//       },
//     },
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           '& .MuiOutlinedInput-notchedOutline': {
//             borderColor: '#5c00cd',
//           },
//           '&:hover .MuiOutlinedInput-notchedOutline': {
//             borderColor: '#d1c4e9',
//           },
//           '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//             borderColor: '#5c00cd',
//           },
//         },
//         input: {
//           color: '#5c00cd',
//         },
//       },
//     },
//   },
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={theme}> */}
      <CssBaseline />
      <NotificationProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/alterarsenha' element={<AlterarSenha />} />
            <Route path='/assinantes' element={<Assinantes />} />
            <Route path='/biblioteca' element={<Biblioteca />} />
            <Route path='/contatos' element={<Contatos />} />
            <Route path='/esqueci' element={<EsqueciSenha />} />
            <Route path='/funil' element={<Funil />} />
            <Route path='/funis' element={<Funis />} />
            <Route path='/etapas' element={<Etapas />} />
            <Route path='/login' element={<Login />} />
            <Route path='/reset-password/:token' element={<RedefinirSenha />} />
            <Route path='/register' element={<Register />} />
            <Route path='/setores' element={<Setores />} />
          </Routes>
        </BrowserRouter>
      </NotificationProvider>
    {/* </ThemeProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
