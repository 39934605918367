import axios from "axios";

const baseAPI = process.env.REACT_APP_BASE_API_URL
const assinanteAPI = axios.create({baseURL: `${baseAPI}api/funil/assinantes` })

async function getAssinantes(token) {

    const authToken = `Token ${token}`
    // console.log('authToken: ', authToken)
    const resp = await assinanteAPI.get('/', {headers: { Authorization: authToken }})

    return resp.data
}

async function getAssinante(token, id) {
    const authToken = `Token ${token}`
    const resp = await assinanteAPI.get(`/${id}`, {headers: { Authorization: authToken }})
    return resp.data
}

async function newAssinante(dados, token) {
    const authToken = `Token ${token}`
    const resp = await assinanteAPI.post('/', dados, {headers: { Authorization: authToken, "Content-Type": "application/json"}})
    return resp.data
}

async function updAssinante(dados, token) {
    const authToken = `Token ${token}`
    const resp = await assinanteAPI.put(`/${dados.id}`, dados, {headers: { Authorization: authToken }})
    return resp.data
}

async function delAssinante(pk, token) {
    const authToken = `Token ${token}`
    const resp = await assinanteAPI.delete(`/${pk}`, {headers: { Authorization: authToken }})
    return resp.data
}

export {
    getAssinantes, newAssinante, updAssinante, delAssinante, getAssinante
}