import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, MenuItem, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSetores } from "../../services/SrvSetor";
import toast, { Toaster } from "react-hot-toast";
import { addLista, updLista } from "../../services/SrvTemplateFunil";

export default function TemplateListaFunilForm({ token, etapa, show, handleClose, fetchEtapas }) {
    const [formValues, setFormValues] = useState({
        nome: etapa?.nome || "",
        horas_expiracao: etapa?.horas_expiracao || 0,
        horas_uteis: etapa?.horas_uteis || false,
        permite_entrada: etapa?.permite_entrada || false,
        mostra_abordagens: etapa?.mostra_abordagens || false,
        exibe_calendario: etapa?.exibe_calendario || false,
        ordem: etapa?.ordem || 0,
        setores: etapa?.setores || []
    })

    const [options, setOptions] = useState([])

    const fetchSetores = async () => {
        await getSetores(token).then(
            (resp) => {
                setOptions(resp)
            },
            (erro) => {
                toast(`Ocorreu um erro ao recuperar setores: ${erro}`)
            }
        )
    }
    useEffect(() => {
        fetchSetores()
    }, [])

    // console.log('etapa: ', etapa)

    const handleChange = (event) => {
        console.log('no onchange: ', event.target)
        if (event.target.type === "checkbox") {
            const { name, checked } = event.target
            setFormValues({
                ...formValues,
                [name]: checked
            })
        } else {
            const { name, value } = event.target
            setFormValues({
                ...formValues,
                [name]: value
            })
        }
    }

    return (
        <Dialog
            open={show}
            fullWidth
            PaperProps={{
                component: "form",
                onSubmit: async (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    console.log('formJson no submit - antes: ', formJson);
                    var setores = formJson['setores']
                    setores = setores.split(',')
                    formJson['setores'] = setores
                    console.log('formJson no submit - depois: ', formJson);
                    if (!etapa) {
                        await addLista(token, formJson).then(
                            (resp) => {
                                toast.success('Etapa salva com sucesso!')

                                if (fetchEtapas) {
                                    fetchEtapas()
                                }
                                // setFormValues({
                                //     nome: "",
                                //     listas: []
                                // })
                                if (handleClose) {
                                    handleClose()
                                }
                            },
                            (erro) => {
                                console.log('erro: ', erro)
                                toast.error('Ocorreu um erro ao tentar salvar a Etapa: ', erro)
                            }
                        )
                    } else {
                        await updLista(token, etapa.id, formJson).then(
                            (resp) => {
                                toast.success('Etapa atualizada com sucesso!')
                                fetchEtapas()
                                handleClose()
                            },
                            (erro) => {
                                toast.error(`Ocorreu um erro ao tentar salvar a etapa: ${erro}`)
                            }
                        )
                    }
                }
            }}
        >
            {/* 'id', 'nome', 'setores', 'horas_expiracao', 'horas_uteis', 'ordem', 'permite_entrada', 'mostra_abordagens', 'exibe_calendario' */}
            <DialogTitle>{etapa ? "Atualizar Etapa" : "Nova Etapa"}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
                    <TextField
                        label="Nome"
                        id="nome"
                        name="nome"
                        required
                        fullWidth
                        size="small"
                        value={formValues.nome}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Horas para expiração"
                        id="horas_expiracao"
                        name="horas_expiracao"
                        type="number"
                        required
                        fullWidth
                        size="small"
                        value={formValues.horas_expiracao}
                        onChange={handleChange}
                    />
                    <FormGroup sx={{ border: "1px solid", padding: 1, display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
                        <FormControlLabel control={<Switch id="horas_uteis" name="horas_uteis" checked={formValues.horas_uteis} onChange={handleChange} />} label="horas úteis?" />
                        <FormControlLabel control={<Switch id="permite_entrada" name="permite_entrada" checked={formValues.permite_entrada} onChange={handleChange} />} label="permite entrada?" />
                        <FormControlLabel control={<Switch id="mostra_abordagens" name="mostra_abordagens" checked={formValues.mostra_abordagens} onChange={handleChange} />} label="mostra tentativas?" />
                        <FormControlLabel control={<Switch id="exibe_calendario" name="exibe_calendario" checked={formValues.exibe_calendario} onChange={handleChange} />} label="exibe calendário?" />
                    </FormGroup>
                    <TextField
                        label="Selecione setores"
                        id="setores"
                        name="setores"
                        select
                        fullWidth
                        required
                        value={formValues.setores}
                        onChange={handleChange}
                        SelectProps={{
                            multiple: true,
                            renderValue: (selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((id) => {
                                        const option = options.find((opt) => opt.id === id);
                                        return option ? <Chip key={id} label={option.nome} /> : null;
                                    })}
                                </Box>
                            ),
                        }}
                        sx={{ mb: 2 }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.nome}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Ordem no funil"
                        id="ordem"
                        name="ordem"
                        type="number"
                        required
                        fullWidth
                        size="small"
                        value={formValues.ordem}
                        onChange={handleChange}
                    />
                    <Toaster toastOptions={{ duration: 10000 }}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button type="submit">Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}