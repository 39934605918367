import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, Paper, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SetoresForm from "./SetoresForm";
import { delSetor, getSetores } from "../../services/SrvSetor";
import toast, { Toaster } from "react-hot-toast";


export default function SetoresList({ token }) {

    const [setores, setSetores] = useState([])
    const [showUpd, setShowUpd] = useState(false)
    const [showDel, setShowDel] = useState(false)
    const [setorSelecionado, setSetorSelecionado] = useState(null)

    const fetchSetores = async () => {
        await getSetores(token).then(
            (resp) => {
                setSetores(resp)
            },
            (erro) => {
                toast.error(`Ocorreu um erro ao recuperar os setores: ${erro}`)
            }
        )
    }

    useEffect(() => {
        fetchSetores()
        // eslint-disable-next-line
    }, [])

    const handleUpdateClick = (setor) => {
        setSetorSelecionado(setor)
        setShowUpd(true)
    }

    const handleDeleteClick = (setor) => {
        setSetorSelecionado(setor)
        setShowDel(true)
    }

    const handleAddClick = () => {
        setSetorSelecionado(null)
        setShowUpd(true)
    }

    const handleUpdClose = () => {
        setShowUpd(false)
        fetchSetores()
    }

    const handleDelClose = (refresh) => {
        setShowDel(false)
        if (refresh) {
            fetchSetores()
        }
    }

    const handleDeleteSetor = async () => {
        if (setorSelecionado) {
            await delSetor(token, setorSelecionado.id).then(
                (resp) => {
                    toast.success("Setor excluído com sucesso!")
                    handleDelClose(true)
                },
                (erro) => {
                    const resp = erro.response
                    console.log(resp.data)
                    toast.error(`Ocorreu um erro ao excluir o setor: ${resp.data.error}`)
                    handleDelClose(true)
                }
            )
        }
    }

    return (
        <Box>
            <List>
                {
                    setores.map((setor, index) => (
                        <Box component={Paper} key={index} elevation={3} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor: "rgba(255, 255, 240, 0.5)", backgroundImage: 'url(/images/so-white.png)', backgroundRepeat: "repeat", backgroundBlendMode: "multiply", border: "1px solid", borderRadius: "10px", marginTop: 2 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexGrow: 1, alignItems: "center", margin: 2 }}>
                                <ListItem>
                                    <ListItemText primary={setor.nome} secondary={setor.descricao} />
                                </ListItem>
                                <Card component={Paper} elevation={5} variant="elevation" sx={{ maxWidth: 600, backgroundColor: "#F5F5F5", flexGrow: 1, display: "flex", flexDirection: "column" }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            Mais informações
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" component="div" marginTop={1}>
                                            Existem <span style={{ fontWeight: "bold", color: "primary" }}>{setor.assinantes?.length}</span> assinantes deste setor.
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" component="div" marginTop={1}>
                                            Existem <span style={{ fontWeight: "bold", color: "primary" }}>{setor.templates?.length}</span> funis cadastrados para este setor.
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" component="div" marginTop={1}>
                                            <span style={{ fontWeight: "bold", color: "primary" }}>{setor.etapas?.length}</span> etapas podem ser utilizadas para a criação de funis para este setor.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", margin: 1, gap: 0.5 }}>
                                <Tooltip title="Atualizar" placement="right">
                                    <IconButton onClick={(event) => { handleUpdateClick(setor) }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Excluir" placement="right">
                                    <IconButton onClick={(event) => { handleDeleteClick(setor) }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    ))
                }
            </List>
            <Box sx={{ mt: 2 }}>
                <Tooltip title="Criar novo Setor">
                    <Button variant="contained" endIcon={<AddCircleIcon />} onClick={handleAddClick}>
                        Novo
                    </Button>
                </Tooltip>
            </Box>
            <SetoresForm token={token} setor={setorSelecionado} open={showUpd} handleClose={handleUpdClose} />
            <Dialog
                open={showDel}
            >
                <DialogTitle>Exclusão</DialogTitle>
                <DialogContent>
                    {`Confirma a exclusão do setor ${setorSelecionado?.nome || ""}?`}
                </DialogContent>
                <DialogActions>
                    <Button onClick={(event) => { handleDelClose(false) }}>Cancelar</Button>
                    <Button onClick={(event) => { handleDeleteSetor() }}>Confirmar</Button>
                </DialogActions>
            </Dialog>
            <Toaster toastOptions={{ duration: 10000 }}/>
        </Box>
    )
}
